import logo from './logo.svg';
import './App.css';
import { useTranslation } from "react-i18next";

const lngs = [
  { code: "nl", native: "Nederlands" },
  { code: "fr", native: "Francais" },
  { code: "de", native: "Deutch" },
];

function App() {
  const { t, i18n } = useTranslation();

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };
  return (
    <div className="App">
      <div id="container">
        {/*<div className="flags">*/}
        {/*  <img src="images/flag-deutch.png" title="Deutch" id="lang_german" alt="Deutch" />*/}
        {/*    <img src="images/flag-francais.png" title="Français" id="lang_french" alt="Français" />*/}
        {/*      <img src="images/flag-nederlands.png" title="Nederlands" id="lang_dutch" alt="Nederlands" />*/}
        {/*</div>*/}
        <div className="logo"><a href="index.php"><img src="images/logo.png" alt="IFPS" /></a></div>
        <div id="header">
          <ul id="nav">

            {/*<li className="first"><a href="index.php" title="International Fall Protection Systems"*/}
            {/*                         id="link_home">home</a></li>*/}
            {/*<li>Kontakt</li>*/}
          </ul>
        </div>

        <div id="content">

          <div className="topheroes clear">
            <div className="tophero th1"></div>
            <div className="tophero th2"></div>
            <div className="tophero th3"></div>
            <div className="tophero th4"></div>
          </div>
          <div className="topheroes-txt clear">
            <div className="band b1">Höhenarbeit</div>
            <div className="band b2">Produkte</div>
            <div className="band b3">Beratung</div>
            <div className="band b4">Bilder</div>
          </div>


          <div className="main-section clear">

            <div className="c c1">
              <div className="cc">
                <h1 className="t1">
                  Willkommen
                </h1>

                <p>I.F.P.S. International Fall Protections Systems S.à r.l, kann Ihnen für jede Situation bei
                  Höhenarbeiten ein komplettes Programm mit Lösungen für Absturzsicherungen anbieten. </p>
                <p>Gearbeitet wird nur mit Herstellern, die einen positiven Fallversuch Ihrer Anschlagpunkte und
                  Befestigungen nachweisen können.</p>
                <p>Die Sicherheitskonzepte werden, wenn es technisch möglich ist, so berechnet, dass es NICHT zum Sturz
                  kommen kann.</p>
                <p>Nicht nur der Preis wird abgewogen, sondern das ganze System wird fachgerecht kontrolliert.</p>
                <p>Es geht hier nämlich an erster Stelle um Menschenleben. </p>

                <p><em>Lindsay-Yves FABER</em></p>

              </div>
            </div>

            <div className="c c3">
              <div className="cc">
                <h1>
                  Lösungen für:
                </h1>

                <ul>
                  <li>Anseilschutz</li>
                  <li>Schienensysteme</li>
                  <li>Leitern aus Aluminium mit Rückenschutz</li>
                  <li>Anschlagpunkte</li>
                  <li>PSA , Persönliche Schutz Ausrüstung</li>
                  <li>Seilunterstützte Höhenarbeit</li>
                  <li>Temporäre Absturzsicherungen</li>
                </ul>

              </div>

            </div>

          </div>

        </div>


        <div id="footer">
          <small>
            <label>Tel:</label>&nbsp;00352 661 60 19 63
            <label>Email:</label>&nbsp;<a href="mailto:info@ifps.lu">info@ifps.lu</a>
            <br />Copyright © 2024 IFPS.LU, All Rights Reserved.
          </small>
        </div>

      </div>
    </div>
  );
}

export default App;
